import React from 'react'
import { changeLocale } from 'gatsby-plugin-intl'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { GrLanguage } from 'react-icons/gr'

interface Language {
  color: string
}

const languageName = {
  en: 'English',
  fr: 'Français',
}

const languageClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, language: string) => {
  e.preventDefault()
  changeLocale(language)
}

const Language = ({ color }: Language): JSX.Element => {
  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav caret>
        <GrLanguage className={`grLanguage ${color}`} size={25} />
      </DropdownToggle>
      <DropdownMenu>
        {Object.entries(languageName).map(([key, value]) => (
          <DropdownItem key={key} onClick={(e) => languageClick(e, key)}>
            {value}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default Language
