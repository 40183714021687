import React, { useEffect } from 'react'
import { Container } from 'reactstrap'
import FacebookIcon from 'mdi-react/FacebookIcon'
import LinkedinIcon from 'mdi-react/LinkedinIcon'
import InstagramIcon from 'mdi-react/InstagramIcon'
import EmailIcon from 'mdi-react/EmailIcon'
import MapMarkerIcon from 'mdi-react/MapMarkerIcon'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import fr from '../../intl/fr.json'
import en from '../../intl/en.json'

const Footer = (): JSX.Element => {
  useEffect(() => {
    function scrollOnClick() {
      setTimeout(
        () =>
          window.scroll({
            top: document.body.scrollHeight,
            behavior: 'smooth',
          }),
        100,
      )
    }
    document.getElementById('contactBtn')?.addEventListener('click', scrollOnClick)

    return function cleanup() {
      document.getElementById('contactBtn')?.removeEventListener('click', scrollOnClick)
    }
  }, [])
  return (
    <footer className="footer footer-black footer-white">
      <Container className="footer-max-width">
        <div className="credits ml-auto">
          <a
            className="mr-1 btn btn-link"
            title={useIntl().locale === 'en' ? en.footer_facebook : fr.footer_facebook}
            href="https://www.facebook.com/IngenieusesETS/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FacebookIcon />
          </a>
          <a
            className="mr-1 btn btn-link"
            title={useIntl().locale === 'en' ? en.footer_instagram : fr.footer_instagram}
            href="https://www.instagram.com/ingenieuses/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <InstagramIcon />
          </a>
          <a
            className="mr-1 btn btn-link"
            title={useIntl().locale === 'en' ? en.footer_linkedin : fr.footer_linkedin}
            href="https://www.linkedin.com/company/les-ing%C3%A9nieuses/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <LinkedinIcon />
          </a>
          <a
            className="mr-1 btn btn-link"
            title={useIntl().locale === 'en' ? en.footer_email : fr.footer_email}
            href="mailTo:ingenieuses@ens.etsmtl.ca"
            id="contactBtn"
          >
            <EmailIcon /> <span className="contact">ingenieuses@ens.etsmtl.ca</span>
          </a>
          <a
            className="mr-1 btn btn-link"
            title={useIntl().locale === 'en' ? en.footer_address : fr.footer_address}
            href="https://goo.gl/maps/bucFTWw92iingTBs9"
            rel="noopener noreferrer"
            target="_blank"
          >
            <MapMarkerIcon /> <span className="contact">1219 Rue William, Montréal, QC, H3C 1R1, local D-2013</span>
          </a>
        </div>
        <div className="footer-nav">
          <FormattedMessage id="footer_creationWebsite" /> <i className="fa fa-heart heart" />.
        </div>
      </Container>
    </footer>
  )
}

export default Footer
