/* eslint-disable max-len */
import React from 'react'
import { Helmet } from 'react-helmet'

interface PageHelmet {
  title: string
  description: string
  url: string
  image: string
}

const PageHelmet = ({ title, description, url, image }: PageHelmet): JSX.Element => {
  return (
    <Helmet>
      <link href="https://fonts.googleapis.com/css?family=Montserrat:400,700,200" rel="stylesheet" />
      <link href="https://maxcdn.bootstrapcdn.com/font-awesome/latest/css/font-awesome.min.css" rel="stylesheet" />
      <title>Les Ingénieuses de l&apos;ÉTS</title>
      <meta name="description" content={description} />
      <meta
        name="keywords"
        content="Ingénieuses, ets, ÉTS, ETS, Femmes en génie, Femmes, STEM, STIM, Les Ingénieuses de l'ÉTS, Regroupement étudiant, ingenieuses"
      />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={image} />
    </Helmet>
  )
}

export default PageHelmet
