import React from 'react'
import { NavItem, NavLink } from 'reactstrap'
import { Link } from 'gatsby'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'

interface HeaderItem {
  titleLabel: string
  url: string
  toggleNavbar: VoidFunction
}

const HeaderItem = ({ titleLabel, url, toggleNavbar }: HeaderItem): JSX.Element => {
  const language = useIntl().locale || 'fr'
  const urlWithLocale = language === 'en' ? `/en${url}` : url
  const handleClick = () => {
    if (typeof window === 'undefined') {
      return
    }

    if (window.innerWidth < 992) {
      toggleNavbar()
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <NavItem>
      <NavLink to={urlWithLocale} tag={Link} onClick={handleClick}>
        <FormattedMessage id={titleLabel} />
      </NavLink>
    </NavItem>
  )
}
export default HeaderItem
