import { useEffect } from 'react'
import { Location } from 'shared/types'

interface ScrollToTop {
  location: Location
}

const ScrollToTop = ({ location }: ScrollToTop): null => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return null
}

export default ScrollToTop
