/* eslint-disable max-len */
import { toast } from 'react-toastify'

export const ToastConfigs = {
  draggable: false,
  closeOnClick: true,
  position: toast.POSITION.BOTTOM_RIGHT,
  autoClose: 5000,
}

export const genericTitle = "Les Ingénieuses de l'ÉTS"
export const genericdescription =
  "Regroupement étudiant de l'ÉTS visant à créer et animer un réseau pour favoriser l'intégration des femmes dans le domaine du génie."
export const baseUrl = 'https://www.ingenieuses.ca'
export const homeImage = 'https://sitewebingenieuses.s3.amazonaws.com/events/5a7/accueil2018/010.jpg'

export const FORM_TYPES = { contactForm: 'Formulaire de contact', sponsorForm: 'Formulaire de partenariat' }

export const PROGRAMS = [
  {
    label: 'Cheminement universitaire en technologie',
    value: 'CURSUS',
  },
  {
    label: 'Génie de la construction',
    value: 'CTN',
  },
  {
    label: 'Génie électrique',
    value: 'ELE',
  },
  {
    label: 'Génie logiciel',
    value: 'LOG',
  },
  {
    label: 'Génie mécanique',
    value: 'MEC',
  },
  {
    label: 'Génie des opérations et de la logistique',
    value: 'GOL',
  },
  {
    label: 'Génie de la production automatisée',
    value: 'GPA',
  },
  {
    label: "Génie des technologies de l'information",
    value: 'TI',
  },
  {
    label: 'Maîtrise',
    value: 'MASTERS',
  },
  {
    label: 'Doctorat',
    value: 'DOCTORATE',
  },
  {
    label: 'DESS',
    value: 'DESS',
  },
  {
    label: 'Technological Academic Path',
    value: 'CURSUS',
  },
  {
    label: 'Construction Engineering',
    value: 'CTN',
  },
  {
    label: 'Electrical Engineering',
    value: 'ELE',
  },
  {
    label: 'Software Engineering',
    value: 'LOG',
  },
  {
    label: 'Mechanical Engineering',
    value: 'MEC',
  },
  {
    label: 'Operations and Logistics Engineering',
    value: 'GOL',
  },
  {
    label: 'Automated Manufacturing Engineering',
    value: 'GPA',
  },
  {
    label: 'Information Technology Engineering',
    value: 'TI',
  },
  {
    label: 'Master',
    value: 'MASTERS',
  },
  {
    label: 'Doctorate',
    value: 'DOCTORATE',
  },
]
